<template>
    <div class="gift-center">
        <div class="state-title">
            <i style="color:#FB5D5E" class="iconfont icon-gift-fill"></i>
            <span>{{$t('userGetGifts.title')}}</span>
        </div>
        <div class="gift-list" v-if="total > 0">
            <div class="gift-item" v-for="(item,index) in getGiftList" :key="index">
                <span @click="goUserForm(item.senderId)" class="user-name">{{item.senderName}}</span>
                <span>{{$t('userGetGifts.text_01')}}</span>
                <span style="color:#666;margin:0 6px">{{$isPulic.timestampToTime(item.createDate)}}</span>
                <span>{{$t('userGetGifts.text_02')}}</span>
                <img style="width:80px;height:80px" :src="$isApi.http + item.fileUrl" alt="">
                <span style="color:#f00">{{item.giftName}}</span>
                <span>，{{$t('userGetGifts.text_03')}}：</span>
                <span style="color:rgba(255,0,0,.6)">{{item.words}}</span>
            </div>
            <el-pagination
                class="page-size-number"
                background
                @current-change="currentChange"
                :page-size="10"
                :current-page="pageNo"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        <div style="line-height:60px;text-align:center;color:rgba(0,0,0,.6)" v-else>{{$t('userGetGifts.text_04')}}</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            getGiftList: [],
            total: 0,
            pageNo: 1,
            sendForm: {}
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage.getItem('sendForm'))
        this.giftLi(this.pageSize)
        this.$store.state.giftNumber = 0
    },
    methods: {
        giftLi(pageNo){
            this.$isAxios.axiosGet(this.$isApi.showReceiveGifts,{pageNo:pageNo,pageSize:10},(res)=>{
                if(res.data.message == 'success'){
                    if(res.data.result){
                        this.getGiftList = res.data.result.list
                        this.total = res.data.result.total
                    }
                }
            })
        },
        currentChange(e){
            this.giftLi(e)
        },
        goUserForm(id){
            sessionStorage['userId'] = id
            this.$nextTick(()=>{
                this.$isAxios.axiosPost(this.$isApi.addVisitor,{userId:id,visitorId:this.sendForm.userId},(fang)=>{
                    this.$router.push({
                        path: "/page/userXQ",
                    })
                })
            })
        }
    }
}
</script>

<style scoped>
.gift-center{
    background: #fff;
    padding: 20px;
}
.gift-list{
    margin-top: 3vh
}
.gift-item{
    height: max-content;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #333;
    padding: 10px;
}
.user-name{
    color: #00f;
    margin-right: 6px;
    cursor: pointer
}
.user-name:hover{
    text-decoration: underline;
}
</style>